import { Box, CircularProgress, Fab, Grid, IconButton, makeStyles, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, Button, Paper, TextField, Chip} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Fragment, useContext, useEffect, useState } from "react";
import AddIcon from '@material-ui/icons/Add';
import { NotificationContext } from "../../Context/NotificationContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import MUIDataTable from "mui-datatables";
import UserService from "./../../Services/UserService";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import LocalBackdrop from "../LocalBackdrop/LocalBackdrop";
import { FormattedMessage, useIntl } from "react-intl";
import { Typography } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from "@material-ui/lab";
import SurveyService from "../../Services/SurveyService";
import SiteService from "../../Services/SiteService";
import { REPORT_TYPES } from "../../Constants/Reports";
import CompanyService from "../../Services/CompanyService";
import DateFormat from "../DateFormat/DateFormat";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(1.5),
        right: theme.spacing(1.5),
    },
    rowButton: {
        padding: '6px'
    },
}));

export default function UserPermissions() {
    const classes = useStyles();
    const { updateNotification } = useContext(NotificationContext);
    const { updateLoading } = useContext(SpinnerContext);
    const [isLoading, setLoading] = useState(true);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteUuid, setDeleteUuid] = useState(null);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [editUuid, setEditUuid] = useState(null);

    // Surveys
    const [surveys, setSurveys] = useState([]);
    const [surveyTitles, setSurveyTitles] = useState({});
    const [selectedSurveys, setSelectedSurveys] = useState([]);
    const [loadingSurveys, setLoadingSurveys] = useState(false);

    // Sites
    const [sites, setSites] = useState([]);
    const [selectedSites, setSelectedSites] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [loadingSites, setLoadingSites] = useState(false);

    // Companies
    const [companies, setCompanies] = useState([]);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [loadingCompanies, setLoadingCompanies] = useState(false);

    const [selectedReports, setSelectedReports] = useState([]);
    const { updateTitle } = useContext(AppBarTitleContext);
    const intl = useIntl();
    let { uuid } = useParams();
    const reports = [...REPORT_TYPES];
    reports.unshift('role-default'); // Default for the user's role

    const [tableData, setTableData] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 20,
        sortOrder: {},
        data: [],
        included: []
    });

    const hiddenOptions = {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
        searchable: false,
        print: false,
        download: false
    };

    const extractSurveys = (value) => {
        if (value.length === 1 && value[0] === null) {
            return value; // "All" selected
        }
        const surveyList = [];
        if (value) {
            value.forEach((combinedHash) => {
                const survey = findIncluded('survey', combinedHash)
                if (survey) {
                    surveyList.push(survey);
                }
            });
        }

        return surveyList;
    }

    const extractCompanies = (value) => {
        const companyList = [];
        if (value) {
            value.forEach((compUuid) => {
                const company = findIncluded('company', compUuid);
                if (company) {
                    companyList.push(company);
                }
            })
        }
        return companyList;
    }

    const extractSites = (value) => {
        if (value.length === 1 && value[0] === null) {
            return value; // "All" selected
        }

        const siteList = [];
        if (value) {
            value.forEach(uuid => {
                const site = findIncluded('site', uuid);
                if (site) {
                    siteList.push(site);
                }
            })
        }
        return siteList;
    }

    const columns = [{
            name: 'id',
            options: hiddenOptions
        },
        {
            label: intl.formatMessage({id: 'userPermissions.type', defaultMessage: 'Type'}),
            name: 'attributes.type',
            options: {
                sort: false,
            }
        },
        {
            label: intl.formatMessage({id: 'userPermissions.permission', defaultMessage: 'Permission'}),
            name: 'attributes.permission',
            options: {
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    if (tableMeta.rowData[1] === 'survey') {
                        const surveyList = extractSurveys(value.survey);
                        const siteList = extractSites(value.site);
                        const companyList = extractCompanies(value.company);

                        return (<Grid container spacing={4} style={{width: '100%'}}>
                            <Grid item style={{width: '25%'}}>
                                <Typography variant="subtitle2">Companies</Typography>
                                {companyList.length ? <ul>
                                    {companyList.map((c, i) => (
                                        <li key={i}>{c.attributes.name}</li>
                                    ))}
                                </ul>
                                : <ul><li><FormattedMessage id="generic.error" defaultMessage="Error" /> (not found)</li></ul>}
                            </Grid>
                            <Grid item style={{width: '25%'}}>
                                <Typography variant="subtitle2"><FormattedMessage id="generic.surveys" defaultMessage="Surveys"/></Typography>
                                {surveyList.length ? <ul>
                                    {surveyList.map((s, i) => (
                                        <li key={i}>{null === s ? <FormattedMessage id="generic.all" defaultMessage="All" /> : surveyTitles[s.id] ? surveyTitles[s.id] : s.attributes.title}</li>
                                    ))}
                                </ul>
                                : <ul><li><FormattedMessage id="generic.error" defaultMessage="Error" /> (not found)</li></ul>}
                            </Grid>
                            <Grid item style={{width: '25%'}}>
                                <Typography variant="subtitle2"><FormattedMessage id="generic.sites" defaultMessage="Sites"/></Typography>
                                {siteList.length ? <ul>
                                    {siteList.map((s, i) => (
                                        <li key={i}>{null === s ? <FormattedMessage id="generic.all" defaultMessage="All" /> : s.attributes.name}</li>
                                    ))}
                                </ul>
                                : <ul><li><FormattedMessage id="generic.error" defaultMessage="Error" /> (not found)</li></ul>}
                            </Grid>
                            <Grid item style={{width: '25%'}}>
                                <Typography variant="subtitle2"><FormattedMessage id="generic.reports" defaultMessage="Reports"/></Typography>
                                {value.report && value.report.length ? <ul>
                                    {value.report.map((rep, i) => (
                                        <li key={i}>{rep}</li>
                                    ))}
                                </ul>
                                : <ul><li>Role Default</li></ul>}
                            </Grid>
                        </Grid>);
                    }

                    if (tableMeta.rowData[1] === 'tool') {
                        return (<span>TODO...</span>);
                    }

                    return null;
                }
            }
        },
        {
            name: 'attributes.createdAt',
            label: intl.formatMessage({id: 'generic.created', defaultMessage: 'Created'}),
            options: {
                customBodyRender: (value) => (<DateFormat date={value} />)
            }
        },
        {
            name: 'attributes.updatedAt',
            label: intl.formatMessage({id: 'generic.updated', defaultMessage: 'Updated'}),
            options: {
                customBodyRender: (value) => (<DateFormat date={value} />)
            }
        },
        {
            name: 'id',
            label: ' ',
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Box>
                        <IconButton className={classes.rowButton} color="primary" onClick={() => {setEditOpen(true); setEditUuid(value); setSelectedSites(extractSites(tableMeta.rowData[2].site)); setSelectedReports(tableMeta.rowData[2].report.length === 0 ? ['role-default'] : tableMeta.rowData[2].report); setSelectedCompanies(extractCompanies(tableMeta.rowData[2].company)); setSelectedSurveys(extractSurveys(tableMeta.rowData[2].survey))}}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton className={classes.rowButton} color="secondary" onClick={() => {setDeleteUuid(value); setDeleteDialogOpen(true); setEditUuid(null); setEditOpen(false); setSelectedSites([]); setSelectedReports(['role-default']); setSelectedSurveys([]);}}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Box>
                ),
                setCellProps: () => ({
                    align: 'right'
                }),
            }

        }
    ];

    const options = {
        setRowProps: row => {
            if (row[0] === editUuid) {
                return {
                    style: { backgroundColor: "rgba(25, 118, 210, 0.08)" }
                };
            }
        },
        textLabels: {
            body: {
                noMatch: isLoading ? '...' : intl.formatMessage({id: 'generic.noData', defaultMessage: 'No data...'}),
            }
        },
        pagination: tableData.count > 20, // Only show pagination if total is above pagination limit
        tableBodyHeight: 'auto',
        enableNestedDataAccess: '.',
        selectableRows: 'none',
        filter: false,
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        serverSide: true,
        count: tableData.count,
        rowsPerPage: tableData.rowsPerPage,
        rowsPerPageOptions: [],
        sortOrder: tableData.sortOrder,
        onTableChange: (action, tableState) => {
            const sortCol = tableState.sortOrder.name ? (tableState.sortOrder.name.replace('attributes.', '')) : 'title';
            const sortDir = tableState.sortOrder.direction ? (tableState.sortOrder.direction === 'asc' ? '' : '-') : '';

            switch (action) {
                case 'changePage':
                    fetchPermissions(tableState.page, tableState.rowsPerPage, sortDir+sortCol, tableState.searchText);
                    break;

                case 'propsUpdate':
                    if (forceRefresh) {
                        setForceRefresh(false, fetchPermissions((tableState.page ? tableState.page : 0), (tableState.rowsPerPage ? tableState.rowsPerPage : 20)));
                    }
                    break;

                default:
                    console.log(action, 'action not handled.');
            }
        },
    };

    const findIncluded = (type, identifier) => {
        for (const entity of tableData.included) {
            if ('survey' === type) {
                if (entity.attributes.combinedHash === identifier) {
                    return entity;
                }
            } else {
                if (entity.id === identifier) {
                    return entity;
                }
            }

        }
        return null;
    };

    const fetchPermissions = (page, pageSize) => {
        console.log('FETCH#', page, pageSize);
        setLoading(true);
        UserService.permissions(uuid, page*pageSize, pageSize)
        .then(function (response) {
            setTableData({
                data: response.data.data,
                count: response.data.meta.count,
                included: response.data.included
            });
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        }).then(function() {
            setLoading(false);
        });
    };

    const fetchUser = () => {
        UserService.user(uuid)
        .then(function (response) {
            setCurrentUser(response.data.data);
            fetchSurveys(response.data.data.relationships.company.id, response.data.data);
            fetchSites(response.data.data.relationships.company.id, response.data.data);
            fetchCompanies(response.data.data);
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        });
    }

    const fetchCompanies = (user) => {
        if (user && user.attributes.role === 'partner') {
            CompanyService.allCompanies('name').then(response => {
                setCompanies(response);
            }).catch(error => {
                console.log(error);
            }).then(() => {
                setLoadingCompanies(false);
            });
        } else {
            // No need for companies
            setCompanies([]);
        }
    }

    const fetchSites = (companyUuid, user) => {
        SiteService.allSites('name', null, companyUuid ? {company: companyUuid} : null).then(response => {
            if (user && user.attributes.role === 'partner') {
                // For now only allow "all" for partner users
                setSites([null]);
            } else {
                response.unshift(null);
                setSites(response);
            }
        }).catch(error => {
            console.log(error);
        }).then(() => {
            setLoadingSites(false);
        });
    }

    const fetchSurveys = (companyUuid, user) => {
        setLoadingSurveys(true);
        SurveyService.allSurveysByHash(null, null, {company: companyUuid}).then(response => {
            if (user && user.attributes.role === 'partner') {
                // For now only allow "all" for partner users
                setSurveys([null]);
            } else {
                const titles = {};
                response.forEach(s => {
                    titles[s.id] = s.relationships['survey-title'] ? s.relationships['survey-title'].data.title : s.attributes.title;
                });
                setSurveyTitles(titles);
                response.unshift(null);
                setSurveys(response);
            }

        }).catch(error => {
            console.log(error);
        }).then(() => {
            setLoadingSurveys(false);
        });
    }

    const handleDelete = () => {
        // To avoid multiple submits
        if (!deleteDialogOpen) {
            return;
        }
        setDeleteDialogOpen(false);
        updateLoading(true);

        UserService.deletePermission(uuid, deleteUuid)
        .then(function (response) {
            setDeleteUuid(null);
            setForceRefresh(true);
        }).catch(function (error) {
            if (error.response && error.response.status) {
                if (error.response.data.error.detail) {
                    updateNotification(true, error.response.data.error.detail, 'error');
                } else {
                    updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                }
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
            console.error(error);
        }).then(function () {
            updateLoading(false);
        });
    }

    useEffect(() => {
        updateTitle(null);
        fetchPermissions(0, tableData.rowsPerPage);
        fetchUser();
        // eslint-disable-next-line
    }, [location.search]);

    const handleSavePermission = () => {
        setLoading(true);
        const permission = {type: 'survey', permission: {
            survey: [],
            site: [],
            report: [],
            company: [],
        }};

        if (selectedReports.length === 1 && selectedReports[0] === 'role-default') {
            permission.permission.report.shift(); // Empty array is role-default
        } else if (selectedReports.length) {
            permission.permission.report.push(...selectedReports);
        }

        if (selectedSurveys.length === 1 && selectedSurveys[0] === null) {
            permission.permission.survey.push(null); // "All" selected
        } else {
            selectedSurveys.forEach(currentSurvey => {
                permission.permission.survey.push(currentSurvey.attributes.combinedHash);
            });
        }

        if (selectedSites.length === 1 && selectedSites[0] === null) {
            permission.permission.site.push(null); // "All" selected
        } else {
            selectedSites.forEach(currentSite => {
                permission.permission.site.push(currentSite.id);
            });
        }

        if (currentUser && currentUser.attributes.role === 'partner') {
            selectedCompanies.forEach(currentComp => {
                permission.permission.company.push(currentComp.id);
            })
        }

        (editUuid ? UserService.patchPermission(uuid, editUuid, permission, {user: {type: 'user', id: uuid}}) : UserService.createPermission(uuid, permission, {user: {type: 'user', id: uuid}}))
        .then(() => {
            setEditUuid(null);
            setEditOpen(false);
            setSelectedCompanies([]);
            setSelectedSites([]);
            setSelectedSurveys([]);
            setSelectedReports([]);
            fetchPermissions(0, (tableData.rowsPerPage ? tableData.rowsPerPage : 20));
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            console.error(error);
        }).then(function () {
            updateLoading(false);
        });
    }

    return(
        <Fragment>
            <Grid container spacing={0} justifyContent="center">
                <Grid item sm={12}>
                    <LocalBackdrop open={isLoading} transitionDuration={600}>
                        <CircularProgress color="secondary" size={40} />
                    </LocalBackdrop>
                    <Typography variant="h6">{currentUser ? 'Permissions for: '+currentUser.attributes.name + ' (email: '+currentUser.attributes.email+', role: '+currentUser.attributes.role+')' : null}</Typography>
                    <MUIDataTable
                        title=""
                        data={tableData.data}
                        columns={columns}
                        options={options}
                    />
                </Grid>
                <Grid item sm={12} style={{paddingBottom: '60px'}}>
                    <Grid item sm={12}>
                        {(editOpen ?
                            <Paper elevation={0}>
                                <Box p={2}>
                                    <Typography variant="h6">{(editUuid ? 'Edit Permission' : 'New Permission')}</Typography>
                                    <Grid container spacing={0} justifyContent="center">
                                        <Grid item sm={3}>
                                            {(currentUser && currentUser.attributes.role === 'partner' ?
                                                <Autocomplete
                                                    multiple
                                                    options={companies}
                                                    loading={loadingCompanies}
                                                    value={selectedCompanies}
                                                    getOptionLabel={(option) => null !== option ? option.attributes.name : 'All'}
                                                    onChange={(event, selected) => {
                                                        if (selected.length === 1 && selected[0] === null) {
                                                            setSelectedCompanies(selected); // "All" selected
                                                        } else if (selected.length && selected[selected.length-1] === null) {
                                                            setSelectedCompanies([null]); // "All" was just added, no reason the keep the rest
                                                        } else {
                                                            setSelectedCompanies(selected.filter(s => s !== null)); // Remove "All" from selected
                                                        }
                                                    }}
                                                    getOptionSelected={(option, value) => option && value ? option.id === value.id : option === value}
                                                    renderInput={(params) => (
                                                        <TextField {...params} margin="normal" label="Companies" required />
                                                    )}
                                                />
                                            :
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    label="Companies"
                                                    required
                                                    value={currentUser.relationships.company.data.name}
                                                    disabled
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item sm={3}>
                                            <Autocomplete
                                                multiple
                                                options={surveys}
                                                loading={loadingSurveys}
                                                value={selectedSurveys}
                                                renderOption={(option) => (
                                                    option !== null ?
                                                        <Fragment>
                                                            {null !== option ? (surveyTitles[option.id] ? surveyTitles[option.id] : option.attributes.title) : null}
                                                            <Chip color="primary" label={'Status: '+option.attributes.providerStatus} size="small" style={{marginRight: 5, marginLeft: 5 }} />
                                                            {option.attributes.lastRespondentAt ? <Chip label={'Last response: '+(new Date(option.attributes.lastRespondentAt)).toLocaleDateString('en-gb', {year: 'numeric', month: 'short', day: 'numeric'})} size="small" style={{marginRight: 5}} />: null}
                                                        </Fragment>
                                                    : 'All'
                                                )}
                                                getOptionLabel={(option) => null !== option ? (surveyTitles[option.id] ? surveyTitles[option.id] : option.attributes.title) : 'All'}
                                                onChange={(event, selected) => {
                                                    if (selected.length === 1 && selected[0] === null) {
                                                        setSelectedSurveys(selected); // "All" selected
                                                    } else if (selected.length && selected[selected.length-1] === null) {
                                                        setSelectedSurveys([null]); // "All" was just added, no reason the keep the rest
                                                    } else {
                                                        setSelectedSurveys(selected.filter(s => s !== null)); // Remove "All" from selected
                                                    }
                                                }}
                                                getOptionSelected={(option, value) => option && value ? option.id === value.id : option === value}
                                                renderInput={(params) => (
                                                    <TextField {...params} margin="normal" label="Surveys" required />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item sm={3}>
                                            <Autocomplete
                                                multiple
                                                options={sites}
                                                loading={loadingSites}
                                                value={selectedSites}
                                                getOptionLabel={(option) => null !== option ? option.attributes.name : 'All'}
                                                onChange={(event, selected) => {
                                                    if (selected.length === 1 && selected[0] === null) {
                                                        setSelectedSites(selected); // "All" selected
                                                    } else if (selected.length && selected[selected.length-1] === null) {
                                                        setSelectedSites([null]); // "All" was just added, no reason the keep the rest
                                                    } else {
                                                        setSelectedSites(selected.filter(s => s !== null)); // Remove "All" from selected
                                                    }
                                                }}
                                                getOptionSelected={(option, value) => option && value ? option.id === value.id : option === value}
                                                renderInput={(params) => (
                                                    <TextField {...params} margin="normal" label="Sites" required />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item sm={3}>
                                            <Autocomplete
                                                multiple
                                                groupBy={(option) => option === 'premium' ? 'Report Group' : (option === 'filter-demographic' ? 'Feature' : 'Individual Reports')}
                                                options={reports}
                                                loading={false}
                                                value={selectedReports}
                                                getOptionLabel={(option) => null !== option ? option : 'All'}
                                                onChange={(event, selected) => {
                                                    if (selected.length && selected[selected.length-1] === 'role-default') {
                                                        setSelectedReports(['role-default']); // role-default was just added, no reason to keep the rest
                                                    } else {
                                                        setSelectedReports(selected.filter(s => s !== 'role-default')) // Remove "default" from selected
                                                    }
                                                }}
                                                disabled={currentUser && currentUser.attributes.role === 'partner'}
                                                renderInput={(params) => (
                                                    <TextField {...params} margin="normal" label="Reports" />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button onClick={handleSavePermission} variant="contained" color="secondary" disabled={((selectedCompanies.length === 0 && currentUser && currentUser.attributes.role === 'partner') || selectedSites.length === 0 || selectedSurveys.length === 0)}>
                                        {(editUuid ? 'Save' : 'Create')}
                                    </Button>
                                </Box>
                            </Paper>
                        : null)}
                    </Grid>
                </Grid>
            </Grid>
            <Fab className={classes.fab} color="secondary" onClick={() => { setEditOpen(true); setEditUuid(null); setSelectedSurveys([]); setSelectedCompanies([]); setSelectedSites([]); setSelectedReports(['role-default']);}}>
                <AddIcon />
            </Fab>
            <Dialog
                open={deleteDialogOpen}
                onClose={() => {setDeleteDialogOpen(false)}}
            >
                <DialogTitle><FormattedMessage id="generic.delete" defaultMessage="Delete" /></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="userPermissions.confirmDelete" defaultMessage="This will delete the permission, continue?" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setDeleteDialogOpen(false)}} color="primary" autoFocus>
                        <FormattedMessage id="generic.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button variant="contained" onClick={handleDelete} color="secondary">
                        <FormattedMessage id="generic.confirm" defaultMessage="Confirm" />
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
