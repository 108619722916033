export function randomPassword(length = 24) {
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercase = uppercase.toLowerCase();
    const numbers = '0123456789';
    const specials = '!@#$%€&*()+[]{};:,.<>?';
    const all = uppercase + lowercase + numbers + specials;
    let password = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xFFFFFFFF + 1) * all.length);
        password += all.charAt(randomIndex);
    }

    return password;
}